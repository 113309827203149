import React from 'react';

import { useAuth } from '@context/auth';
import { MilestonesModel } from '@models/milestones';
import { AppointmentStatsModel } from '@models/yoga-sauna/appointment';
import {
  useFetchAppointmentStats,
  useFetchMilestones,
} from '@services/appointments';
import { useFetchUserCards } from '@services/user';

const useNextMilestone = (): {
  nextMilestone: MilestonesModel | undefined;
  hasReached500: boolean;
  hasReached200: boolean;
  hasReached100: boolean;
  hasReached10: boolean;
  appointmentStats: AppointmentStatsModel | undefined;
  currMilestone: MilestonesModel | undefined;
  completedMilestone: MilestonesModel | undefined;
  showCongratulationsModal: MilestonesModel | undefined;
  isUserLoading: boolean;
  isMilestonesLoading: boolean;
} => {
  const { user } = useAuth();
  const { data: userData, isLoading: isUserLoading } = useFetchUserCards({
    enabled: !!user,
  });
  const {
    data: milestones = [],
    isLoading: isMilestonesLoading,
  } = useFetchMilestones({
    enabled: !!user,
  });
  const { data: appointmentStats } = useFetchAppointmentStats({
    enabled: !!user,
  });
  // adding || 0 for testing
  const appointmentCount = appointmentStats?.totalCount || 0;

  const hasReached500 = appointmentCount >= 500; // 500;
  const hasReached200 = appointmentCount >= 200; // 200;
  const hasReached100 = appointmentCount >= 100; // 100;
  const hasReached10 = appointmentCount >= 10; // 10;
  const nextMilestone = React.useMemo(
    () => milestones.find(item => appointmentCount < item.noOfSession),
    [milestones, appointmentCount],
  );
  const currMilestone = React.useMemo(
    () => milestones.find(item => item.noOfSession >= appointmentCount),
    [milestones, appointmentCount],
  );
  const completedMilestone = React.useMemo(
    () => milestones.filter(item => item.noOfSession <= appointmentCount).pop(),
    [milestones, appointmentCount],
  );
  const showCongratulationsModal = React.useMemo(
    () =>
      milestones.find(
        item =>
          item.noOfSession === appointmentCount &&
          userData?.lastMilestoneId !== item.id,
      ),
    [milestones, appointmentCount, userData?.lastMilestoneId],
  );

  return React.useMemo(
    () => ({
      nextMilestone,
      hasReached500,
      hasReached200,
      hasReached100,
      hasReached10,
      appointmentStats,
      currMilestone,
      completedMilestone,
      showCongratulationsModal,
      isUserLoading,
      isMilestonesLoading,
    }),
    [
      nextMilestone,
      hasReached500,
      hasReached200,
      hasReached100,
      hasReached10,
      appointmentStats,
      currMilestone,
      completedMilestone,
      showCongratulationsModal,
      isUserLoading,
      isMilestonesLoading,
    ],
  );
};

export default useNextMilestone;
