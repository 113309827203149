import React from 'react';
import { ReactQueryConfig, ReactQueryConfigProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';

import { AuthProvider } from '@context/auth';
import { SaunaProvider } from '@context/sauna';
import { YogaProvider } from '@context/yoga';
import { YogaSaunaProvider } from '@context/yoga-sauna';

import { GiftProvider } from './gift';
import { MembershipsProvider } from './membership';
import { PreopeningProvider } from './pre-opening';

const queryConfig: ReactQueryConfig = {
  queries: {
    refetchOnWindowFocus: false,
    retry: (failureCount: number, error: any) => {
      if (process.env.NODE_ENV === 'test') return false;
      if (error.response?.status === 404) return false;
      if (failureCount < 2) return true;
      return false;
    },
  },
};

const AppProviders: React.FunctionComponent = ({ children }) => (
  <ReactQueryConfigProvider config={queryConfig}>
    <AuthProvider>
      <YogaSaunaProvider>
        <YogaProvider>
          <SaunaProvider>
            <MembershipsProvider>
              <GiftProvider>
                <PreopeningProvider>{children}</PreopeningProvider>
              </GiftProvider>
            </MembershipsProvider>
          </SaunaProvider>
        </YogaProvider>
      </YogaSaunaProvider>
    </AuthProvider>
    <ReactQueryDevtools />
  </ReactQueryConfigProvider>
);

export default AppProviders;
