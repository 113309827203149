import {
  PreOpeningActionTypes,
  PreopeningReducerTypes,
} from '@models/pre-opening';

export const preopeningInitialState = {
  isCardAdded: false,
  isFoundingMembershipPurchased: false,
  isMembershipAlreadyPurchased: false,
};

const PreopeningReducer: PreopeningReducerTypes = (
  state = preopeningInitialState,
  action,
) => {
  switch (action.type) {
    case PreOpeningActionTypes.SET_IS_CARD_ADDED:
      return {
        ...state,
        isCardAdded: action.payload,
      };
    case PreOpeningActionTypes.SET_IS_FOUNDING_MEMBERSHIP_PURCHASED:
      return {
        ...state,
        isFoundingMembershipPurchased: action.payload,
      };
    case PreOpeningActionTypes.SET_IS_MEMBERSHIP_ALREADY_PURCHASED:
      return {
        ...state,
        isMembershipAlreadyPurchased: action.payload,
      };
    default:
      return state;
  }
};

export default PreopeningReducer;
