import React from 'react';
import { queryCache, QueryConfig, useMutation, useQuery } from 'react-query';

import { useClient } from '@context/auth';
import { IFieldsAndFilters } from '@models/common/api-client';
import { ErrorType } from '@models/common/async-hook';
import { MilestonesModel } from '@models/milestones';
import { MutationResponse, QueryResponse } from '@models/react-query';
import {
  AllAppointmentsModel,
  AppointmentAddonItemsModel,
  AppointmentCancelPolicy,
  AppointmentStatsModel,
  EntityStatusModel,
} from '@models/yoga-sauna/appointment';
import { client } from '@services/api-client';
import { parseFieldsAndFilters, parseFilters } from '@utils/api';

interface FetchAppointmentFilterModel {
  'entityStatusId.in'?: string;
  'startDateTimeUtc.ge'?: string;
  'startDateTimeUtc.le'?: string;
}

const useFetchAppointments = (
  params: IFieldsAndFilters<
    Partial<AllAppointmentsModel & FetchAppointmentFilterModel>
  >,
  config: QueryConfig<AllAppointmentsModel[], ErrorType | null> = {},
  dependentKey?: string,
): QueryResponse<AllAppointmentsModel[]> => {
  const authenticatedClient = useClient();
  const { filters: filtersParams } = params;

  const reqFilters = {
    sortField: 'createdAt',
    sortOrder: 'desc',
    ...filtersParams,
  };

  const reqFields = [
    'serviceType.*',
    'instructor.*',
    'status.*',
    'location.*',
    'location.service.*',
    'location.service.service.*',
    'appointmentAddon.*',
    'appointmentAddon.addonLineItems.*',
    'appointmentAddon.addonLineItems.product.*',
  ];

  const { fields, filters } = parseFieldsAndFilters(
    reqFields,
    reqFilters,
    '%26',
  );

  return useQuery({
    queryKey: ['appointments', dependentKey],
    queryFn: () =>
      authenticatedClient<AllAppointmentsModel[], undefined>(
        `appointments?fields=${fields}&filters=${filters}`,
      ),
    config,
  });
};

const useFetchAppointmentStatus = (
  config: QueryConfig<EntityStatusModel[], ErrorType | null> = {},
): QueryResponse<EntityStatusModel[]> => {
  const authenticatedClient = useClient();

  const filters = { entityType: 'appointment' };

  return useQuery({
    queryKey: 'appointments-status',
    queryFn: () =>
      authenticatedClient<EntityStatusModel[], undefined>(
        `entitystatus?filters=${parseFilters(filters, '%26')}`,
      ),
    config,
  });
};

const useFetchAppointmentCancelPolicy = (
  appointmentId: number,
  config: QueryConfig<AppointmentCancelPolicy, ErrorType | null> = {},
): QueryResponse<AppointmentCancelPolicy> => {
  const authenticatedClient = useClient();

  return useQuery({
    queryKey: `appointment-cancel-policy-${appointmentId}`,
    queryFn: () =>
      authenticatedClient<AppointmentCancelPolicy, undefined>(
        `appointments/${appointmentId}/cancelPolicy`,
      ),
    config,
  });
};

const useCancelAppointment = (
  config: QueryConfig<number, ErrorType | null> = {},
): MutationResponse<number> => {
  const authenticatedClient = useClient();

  return useMutation(
    (appointmentId: number) =>
      authenticatedClient(`appointments/${appointmentId}/cancel`, {
        method: 'POST',
      }),
    { ...config },
  );
};

const fetchMilestones = (
  authenticatedClient: typeof client,
): Promise<MilestonesModel[]> => {
  const requestUrl = 'milestones';
  const filters = {
    sortField: 'order',
    sortOrder: 'asc',
  };
  return authenticatedClient<MilestonesModel[], undefined>(
    `${requestUrl}?filters=${parseFilters(filters, '%26')}`,
  );
};

const usePrefetchMilestones = () => {
  const authenticatedClient = useClient();

  return React.useCallback(async () => {
    queryCache.removeQueries('milestones');
    await queryCache.prefetchQuery('milestones', () =>
      fetchMilestones(authenticatedClient),
    );
  }, [authenticatedClient]);
};

const useFetchMilestones = (
  config: QueryConfig<MilestonesModel[], ErrorType | null> = {},
): QueryResponse<MilestonesModel[]> => {
  const authenticatedClient = useClient();

  return useQuery({
    queryKey: 'milestones',
    queryFn: () => fetchMilestones(authenticatedClient),
    config,
  });
};

const fetchAppointmentStats = (
  authenticatedClient: typeof client,
): Promise<AppointmentStatsModel> => {
  const requestUrl = 'appointments/stats';
  return authenticatedClient<AppointmentStatsModel, undefined>(requestUrl);
};

const usePrefetchAppointmentStats = () => {
  const authenticatedClient = useClient();

  return React.useCallback(async () => {
    queryCache.removeQueries('appointment-stats');
    await queryCache.prefetchQuery('appointment-stats', () =>
      fetchAppointmentStats(authenticatedClient),
    );
  }, [authenticatedClient]);
};

const useFetchAppointmentStats = (
  config: QueryConfig<AppointmentStatsModel, ErrorType | null> = {},
): QueryResponse<AppointmentStatsModel> => {
  const authenticatedClient = useClient();

  return useQuery({
    queryKey: 'appointment-stats',
    queryFn: () => fetchAppointmentStats(authenticatedClient),
    config,
  });
};

const useFetchAppointmentAddons = (
  appointmentId: number,
  config: QueryConfig<AppointmentAddonItemsModel, ErrorType | null> = {},
): QueryResponse<AppointmentAddonItemsModel> => {
  const authenticatedClient = useClient();

  return useQuery({
    queryKey: `appointment-${appointmentId}-addons`,
    queryFn: () =>
      authenticatedClient<AppointmentAddonItemsModel, undefined>(
        `appointments/${appointmentId}/addons/stats`,
      ),
    config,
  });
};

export {
  useFetchAppointments,
  useFetchAppointmentStatus,
  useFetchAppointmentCancelPolicy,
  useCancelAppointment,
  usePrefetchMilestones,
  useFetchMilestones,
  usePrefetchAppointmentStats,
  useFetchAppointmentStats,
  useFetchAppointmentAddons,
};
