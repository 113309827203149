import React from 'react';

// import { Router } from 'next/router';
import { useAuth } from '@context/auth';

const Analytics: React.FunctionComponent<React.PropsWithChildren<any>> = ({
  children,
}) => {
  const { user } = useAuth();

  React.useEffect(() => {
    if (user && window && window.analytics) {
      window.analytics.identify(user.id, {
        created: user.createdAt,
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        phone: user.phoneNumber,
      });
    }
  }, [user]);

  return children;
};

export default Analytics;
