import { MutationResult } from 'react-query';

import { ValidationSchema } from '@models/common/validation';

import { ErrorType } from './common/async-hook';

export interface PreOpeningFormValuesModel {
  email: string;
  zipCode: string;
}

export interface PreOpeningCommonModel {
  preOpeningForm: {
    initialValues: PreOpeningFormValuesModel;
    validationSchema: ValidationSchema<PreOpeningFormValuesModel>;
  };
  handleSubmit: (args: PreOpeningFormValuesModel) => void;
}

export interface PreOpeningFormProps extends PreOpeningCommonModel {
  isLoading: boolean;
}

export interface UsePreOpeningHookReturnModel extends PreOpeningCommonModel {
  result: MutationResult<any, ErrorType>;
}

export type PreOpeningPayload = Omit<
  PreOpeningFormValuesModel,
  'handleSubmit'
> & {
  handleSubmit: () => void;
};

export interface PreopeningState {
  isCardAdded: boolean;
  isFoundingMembershipPurchased: boolean;
  isMembershipAlreadyPurchased: boolean;
}

export enum PreOpeningActionTypes {
  SET_IS_CARD_ADDED = 'SET_IS_CARD_ADDED',
  SET_IS_FOUNDING_MEMBERSHIP_PURCHASED = 'SET_IS_FOUNDING_MEMBERSHIP_PURCHASED',
  SET_IS_MEMBERSHIP_ALREADY_PURCHASED = 'SET_IS_MEMBERSHIP_ALREADY_PURCHASED',
}

export type PreopeningActions =
  | {
      type: PreOpeningActionTypes.SET_IS_CARD_ADDED;
      payload: boolean;
    }
  | {
      type: PreOpeningActionTypes.SET_IS_FOUNDING_MEMBERSHIP_PURCHASED;
      payload: boolean;
    }
  | {
      type: PreOpeningActionTypes.SET_IS_MEMBERSHIP_ALREADY_PURCHASED;
      payload: boolean;
    };

export type PreopeningReducerTypes = (
  state: PreopeningState,
  action: PreopeningActions,
) => PreopeningState;

export type PreopeningDispatch = React.Dispatch<PreopeningActions>;
