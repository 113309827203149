import React from 'react';
import { useRouter } from 'next/router';

import { useAuth } from '@context/auth';
import useNextMilestone from '@services/appointments/use-next-milestone';
import { usePostBookingTodos } from '@services/yoga-sauna';
import { SERVICE } from '@utils/constants';
import { isEmpty } from '@utils/lodash-utils';

const TodosComplete: React.FunctionComponent<React.PropsWithChildren<any>> = ({
  children,
}) => {
  const router = useRouter();
  const { user } = useAuth();
  const {
    data: postBookingTodos,
    isLoading: isTodosLoading,
  } = usePostBookingTodos({
    enabled: !!user,
  });

  const {
    showCongratulationsModal,
    isUserLoading,
    isMilestonesLoading,
  } = useNextMilestone();

  const isDataLoading = !isUserLoading && !isMilestonesLoading;
  const isRatingsDue = postBookingTodos?.reviewNudge?.promptReview;
  const isWaiverDue = postBookingTodos?.waiverNudge?.isWavierDue;
  const isPaymentFailed =
    postBookingTodos?.chargeDeclinedNudge.promptUpdateCard;

  if (
    !isTodosLoading &&
    isPaymentFailed &&
    !router.pathname.includes('/payment-failed')
  ) {
    router.replace('/payment-failed');
    return <></>;
  }

  if (isWaiverDue && !router.pathname.includes('/post-booking')) {
    const prefixType =
      postBookingTodos?.waiverNudge.serviceName === SERVICE.yoga
        ? 'book-a-class'
        : 'book-a-sauna';
    router.replace(`/${prefixType}/post-booking/sign-waiver`);
    return <></>;
  }

  // eslint-disable-next-line consistent-return
  setTimeout(() => {
    if (
      isEmpty(showCongratulationsModal) &&
      isRatingsDue &&
      !isTodosLoading &&
      isDataLoading &&
      !router.pathname.includes('/ratings') &&
      router.pathname.includes('/my-sessions')
    ) {
      router.replace('/ratings');
      return <></>;
    }
  }, 1000);

  if (
    !isEmpty(showCongratulationsModal) &&
    !isPaymentFailed &&
    !isRatingsDue &&
    !router.pathname.includes('/my-sessions') &&
    isDataLoading
  ) {
    router.replace('/my-sessions');
    return <></>;
  }
  return children;
};

export default TodosComplete;
