import '../bootstrap';

import React from 'react';
import App from 'next/app';

import Analytics from '@components/analytics';
import TodosComplete from '@components/todos-complete';
import AppProviders from '@context/index';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@reach/tabs/styles.css';
import '../styles/styles.css';

const BRANCH_KEY = process.env.NEXT_PUBLIC_BRANCH_API_KEY;
const SEGMENT_KEY = process.env.NEXT_PUBLIC_SEGMENT_KEY;

// extending the existing window interface to tell it about new property.
declare global {
  interface Window {
    referrerEmailId: string;
    branch: any;
    analytics: any;
  }
}

export default class CustomApp extends App {
  componentDidMount(): void {
    // Initialize Branch
    window?.branch?.init(BRANCH_KEY, (_err: any, data: any) => {
      window.referrerEmailId = data?.data_parsed?.referrer_emailId;
    });

    if (window?.analytics) {
      window?.analytics?.load(SEGMENT_KEY);
    }
  }

  render(): JSX.Element {
    const { Component, pageProps } = this.props;
    return (
      <>
        <AppProviders>
          <Analytics>
            <TodosComplete>
              <Component {...pageProps} />
            </TodosComplete>
          </Analytics>
        </AppProviders>
      </>
    );
  }
}
