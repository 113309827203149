import React from 'react';

import usePersistentReducer from '@hooks/use-persistent-reducer';
import { PreopeningDispatch, PreopeningState } from '@models/pre-opening';
import PreopeningReducer, {
  preopeningInitialState,
} from '@reducers/pre-opening';

const PreopeningContext = React.createContext<PreopeningState | undefined>(
  undefined,
);
const PreopeningDispatchContext = React.createContext<
  PreopeningDispatch | undefined
>(undefined);

const PreopeningProvider: React.FunctionComponent = ({ children }) => {
  const [state, dispatch] = usePersistentReducer(
    PreopeningReducer,
    preopeningInitialState,
    'pre-opening',
  );
  return (
    <PreopeningContext.Provider value={state}>
      <PreopeningDispatchContext.Provider value={dispatch}>
        {children}
      </PreopeningDispatchContext.Provider>
    </PreopeningContext.Provider>
  );
};

const usePreopeningState = (): PreopeningState => {
  const context = React.useContext(PreopeningContext);
  if (context === undefined) {
    throw new Error(
      'usePreopeningState must be used within a PreOpening Provider',
    );
  }
  return context;
};

const usePreopeningDispatch = (): PreopeningDispatch => {
  const context = React.useContext(PreopeningDispatchContext);
  if (context === undefined) {
    throw new Error(
      'usePreopeningDispatch must be used within a MembershipsProvider',
    );
  }
  return context;
};

const usePreopeningData = (): [PreopeningState, PreopeningDispatch] => [
  usePreopeningState(),
  usePreopeningDispatch(),
];

export {
  PreopeningProvider,
  usePreopeningState,
  usePreopeningDispatch,
  usePreopeningData,
};
